import { Component, OnInit } from '@angular/core';
import { EventService } from '../../shared/services/event.service';

@Component({
  selector: 'fz-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  stats = [
    'more-capture',
    'reduce-costs',
    'more-feedbacks',
  ];

  cases = [
    'sebrae',
    'unimed',
  ];

  benefits_bc = [
    'more-channels',
    'time-and-place',
    'forecast',
    'feedback',
  ];

  benefits_ps = [
    'satisfaction',
    'availability',
    'communication',
    'cancellation',
    'feedback',
  ];

  how_it_works = [
    'scheduling',
    'confirmation',
    'check-in',
    'attendance',
    'feedback',
  ];

  constructor() {
    EventService.changeTitle.emit('home');
  }

  ngOnInit() {
    EventService.changeButtonBack.emit(false);
  }

  scroll(el) {
    document.querySelector(el)
      .scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

}
