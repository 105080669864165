<div class="search-container" [ngClass]="{ 'expanded-search' : showSearchField, 'tickets' : isMyTickets }">
  <div class="search-field card" [ngClass]="{ 'hidden' : !showSearchField }">
    <input #srch name="search" matInput [(ngModel)]="search" (keyup)="handler(search)" [placeholder]="placeholder" autofocus>
    <mat-icon class="icon-button clickable" matSuffix>search</mat-icon>
    <mat-icon matSuffix (click)="toggleSearchField(false)">arrow_back</mat-icon>
    <mat-icon matSuffix class="icon-close" *ngIf="showClearSearch()" (click)="clearSearch()">close</mat-icon>
  </div>

  <div [hidden]="showSearchField">
    <mat-icon (click)="toggleSearchField(true)" matSuffix>search</mat-icon>
    <mat-icon matSuffix (click)="toggleSearchFilter(true)" *ngIf="displayFilter">sort</mat-icon>
  </div>
</div>
