import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizedGuard } from './core/guards';
import { ContactComponent } from './modules/contact';
import { ExploreComponent } from './modules/explore';
import { HomeComponent } from './modules/home';
import { PageNotFoundComponent } from './modules/page-not-found';


const routes: Routes = [
  {
    path: 'p',
    loadChildren: () => import('./modules/scheduling/scheduling.module').then(m => m.SchedulingModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./modules/my-account/my-account.module').then(m => m.MyAccountModule)
  },
  {
    path: 'ticket-details',
    loadChildren: () => import('./modules/ticket/ticket.module').then(m => m.TicketModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/fz-user/fz-user.module').then(m => m.FzUserModule)
  },
  { path: 'contact', component: ContactComponent },
  { path: 'explore', component: ExploreComponent },
  { path: 'home', component: HomeComponent },
  { path: ':404', component: PageNotFoundComponent },
  { path: '', component: HomeComponent, canActivate: [AuthorizedGuard], pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
