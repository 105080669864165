import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private geocoder: any;

  constructor(private _mapLoader: MapsAPILoader) {
    this.loadMapsAPI();
  }

  loadMapsAPI(): void {
    if (!this.geocoder) {
      this._mapLoader.load()
        .then(() => {
          this.geocoder = new google.maps.Geocoder();
        });
      }
  }

  geocodeAddress(location: string): Observable<any> {
    return new Observable(observer => {
      this.geocoder.geocode({'address': location}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0].geometry.location) {
            observer.next({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
          }
        } else {
            observer.next({});
        }
        observer.complete();
      });
    });
  }

}
