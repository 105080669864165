<section>
  <h1 class="title-section">{{'title.contact' | translate }}</h1>
  <article class="content-wrap">

    <div class="block-message" *ngIf="formSent">
      <div class="message success">
        <div class="icon-container">
          <i class="fa fa-check" aria-hidden="true"></i>
        </div>
        <h4>{{'messages.success.contact-confirmed' | translate }}</h4>
        <p>{{'messages.success.received-email' | translate }}</p>
      </div>
    </div>

    <div *ngIf="!formSent">
      <h3>{{'contact.hook' | translate }}</h3>
      <p>{{'contact.info' | translate }}
        <a href="mailto:contato@filazero.net">contato@filazero.net</a>
      </p>
    </div>

    <form [formGroup]="contactForm" class="form-container" *ngIf="!formSent">
      <mat-form-field class="item-form">
        <mat-label>{{'labels.name' | translate }}</mat-label>
        <input name="name" matInput formControlName="name" required>
        <mat-error *ngIf="contactForm.get('name').hasError('required') && contactForm.get('name').touched">
          {{'required' | translate:"{value: 'nome'}"}}
        </mat-error>

        <mat-error *ngIf="contactForm.get('name').hasError('minlength') && contactForm.get('name').touched">
          {{'short-value' | translate:"{value: 'Nome'}"}}
        </mat-error>
        <mat-error *ngIf="contactForm.get('name').hasError('maxlength') && contactForm.get('name').touched">
          {{'long-value' | translate:"{value: 'Nome'}"}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="item-form">
        <mat-label>{{'labels.email' | translate }}</mat-label>
        <input name="email" matInput formControlName="emailFrom" type="email" required>
        <mat-error *ngIf="contactForm.get('emailFrom').hasError('required') && contactForm.get('emailFrom').touched">
          {{'required' | translate:"{value: 'email'}"}}
        </mat-error>

        <mat-error *ngIf="contactForm.get('emailFrom').hasError('email') && contactForm.get('emailFrom').touched">
          {{'invalid' | translate:"{value: 'email'}"}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="item-form">
        <mat-label>{{'labels.phone' | translate }}</mat-label>
        <input type="tel" name="phone" matInput formControlName="phone" mask="(99) 99999-9999" required>
        <mat-error *ngIf="contactForm.get('phone').hasError('required') && contactForm.get('phone').touched">
          {{'required' | translate:"{value: 'telefone'}"}}
        </mat-error>
        <mat-error *ngIf="contactForm.get('phone').hasError('minlength') && contactForm.get('phone').touched">
          {{'invalid' | translate:"{value: 'Telefone'}"}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="item-form">
        <mat-label>{{'labels.message' | translate }}</mat-label>
        <textarea name="message" matInput formControlName="message" required></textarea>
      </mat-form-field>

      <button mat-raised-button [disabled]="!contactForm.valid || isLoading" class="fz-btn" (click)="sendEmail()">
        <span *ngIf="isLoading" class="spinner-button"></span>
        {{'actions.send-email' | translate }}
      </button>
    </form>
  </article>
</section>
