import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { FooterComponent, HeaderComponent } from './layouts';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from './shared/pipes/pipes.module';
import { AuthService } from './core/services/auth.service';
import { AuthInterceptorService } from './core/services/auth-interceptor.service';
import { AuthorizedGuard, AuthGuard } from './core/guards';
//import { CreditCardDirectivesModule } from 'angular-cc-library';
import { FilazeroModule } from './modules';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import {

  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
//import { QRCodeModule } from 'angularx-qrcode';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RatingModule } from 'ng-starrating';
import { ServiceWorkerModule } from '@angular/service-worker';

export function getAuthServiceConfigs() {
  const config = {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.GOOGLE_ID)
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(environment.FB_ID)
      }
    ]
  } as SocialAuthServiceConfig
  return config;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FilazeroModule,
    HttpClientModule,
    RatingModule,
    PipesModule,
    // FeedbackService,
    // QRCodeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvffX4c0IKZDe4KL2cD2WPsyNgzaqmmkQ'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // CreditCardDirectivesModule,
    SocialLoginModule,
    MatIconModule,
    MatMenuModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthorizedGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: getAuthServiceConfigs()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
