import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'fz-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit, AfterViewInit {
  @Input() displayFilter = true;
  @Input() handlerSearch: EventEmitter<string>;
  @Input() isMyTickets = false;
  @Input() minLength = 3;
  @Input() placeholder: string;
  @Input() search: string;
  @Input() toggleFilter: EventEmitter<boolean>;
  @ViewChild('srch') inputSearch: ElementRef;
  showSearchField = false;

  constructor() { }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.inputSearch) {
        this.inputSearch.nativeElement.focus();
      }
    }, 300);
  }

  ngOnInit() { }

  toggleSearchField(value: boolean): void {
    if (value) {
      setTimeout(() => {
        if (this.inputSearch) {
          this.inputSearch.nativeElement.focus();
        }
      }, 300);
    }
    this.showSearchField = value;
  }

  toggleSearchFilter(value: boolean): void {
    this.toggleFilter.emit(value);
  }

  handler(search: string): void {
    if (search.length >= this.minLength || search.length === 0) {
      this.handlerSearch.emit(search);
    }
  }

  clearSearch(): void {
    this.search = '';
    this.handler(this.search);
  }

  showClearSearch() {
    if (this.search) {
      return this.search.length > 0;
    }
  }
}
