import { Injectable, EventEmitter } from '@angular/core';
import { Template } from '../../modules/scheduling/models/company.model';
import { TitleCustomize } from '../../modules/scheduling/models/title-customize.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  static changeTemplate = new EventEmitter<Template>();
  static changeLogoRoute = new EventEmitter<string>();
  static changeTitle = new EventEmitter<string>();
  static changeTitleCustomize = new EventEmitter<TitleCustomize>();
  static changeLanguage = new EventEmitter<string>();
  static stopLoadding = new EventEmitter<boolean>();
  static changeButtonBack = new EventEmitter<boolean>();
  static termsModal = new EventEmitter<boolean>();

  /* Eventos de Login */
  static loginModal = new EventEmitter<boolean>();
  static afterLogin = new EventEmitter();

  constructor() { }
}
