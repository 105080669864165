export const environment = {
  production: false,
  API_URL: 'https://api.dev.filazero.net/',
  API_URL_V2: 'https://api.dev.filazero.net/v2/',
  CLIENT_ID: 'filazeroSite-dev',
  FB_ID: '1484694105127139',
  GOOGLE_ID : '747494649248-k7o7914bqesp2t66qg5bc0ocveq8shrh.apps.googleusercontent.com',
  SITE: 'https://site.dev.filazero.net/',
  URL_ORIGIN: 'https://app.dev.filazero.net/#',
  PUSHER_KEY: '0041ab94e110de594bdb',
  PREFIX_SMART_CODE: 'SC'
};
