import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contact } from '../models/contact.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  API_URL: string = environment.API_URL;

  constructor(private _http: HttpClient) {}

  send(contact: Contact): Observable<any> {
    return this._http.post<any>(`${this.API_URL}api/email`, contact);
  }
}
