import { CompanyServices, BookingService } from '../../modules/scheduling/models/service.model';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Company, Template } from '../../modules/scheduling/models/company.model';
import { Observable } from 'rxjs';
import { BusinessUnitsOfCompany, BusinessUnit } from '../../modules/scheduling/models/business-unit.model';
import { BusinessUnitServices } from '../../modules/scheduling/models/service.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  static companyChanged: EventEmitter<Company> = new EventEmitter<Company>();
  API_URL = environment.API_URL;

  constructor(private readonly _http: HttpClient) { }

  getCompanies(): Observable<Company[]> {
    return this._http.get<Company[]>(`${this.API_URL}api/companies`);
  }

  searchCompanies(search: string): Observable<Company[]> {
    return this._http.get<Company[]>(`${this.API_URL}api/companies?search=${search}`);
  }

  getTemplate(slug: string): Observable<Template> {
    return this._http.get<Template>(`${this.API_URL}api/companies/${slug}/template`);
  }

  getBusinessUnits(slug: string): Observable<BusinessUnit[]> {
    return this._http.get<BusinessUnit[]>(`${this.API_URL}api/companies/${slug}/business-units`);
  }

  searchBusinessUnits(slug: string, search: string): Observable<BusinessUnitsOfCompany> {
    return this._http.get<BusinessUnitsOfCompany>(`${this.API_URL}api/companies/${slug}/business-units?search=${search}`);
  }

  getAbleServicesOfBusinessUnit(companySlug: string, businessUnitSlug: string): Observable<BusinessUnitServices> {
    return this._http.get<BusinessUnitServices>
    (`${this.API_URL}api/companies/${companySlug}/business-units/${businessUnitSlug}/services`);
  }

  searchAbleServicesOfBusinessUnit(companySlug: string, businessUnitSlug: string, serviceName: string): Observable<BusinessUnitServices> {
    return this._http.get<BusinessUnitServices>
    (`${this.API_URL}api/companies/${companySlug}/business-units/${businessUnitSlug}/services?search=${serviceName}`);
  }

  getCompanieservices(slug: string): Observable<CompanyServices> {
    return this._http.get<CompanyServices>(`${this.API_URL}api/companies/${slug}/services`);
  }

  searchCompanieservices(slug: string, concatUrl: string = ''): Observable<CompanyServices> {
    return this._http
      .get<CompanyServices>(`${this.API_URL}api/companies/${slug}/services` + concatUrl);
  }

  getCompanieservice(slug: string, abstractServiceId: number): Observable<BookingService> {
    return this._http.get<BookingService>(`${this.API_URL}api/companies/${slug}/services/${abstractServiceId}`);
  }
}
