<section>
  <h1 class="title-section">{{ 'scheduling.explore.title' | translate }}</h1>
  <article>
    <div class="search-container">
      <fz-search [search]="search"
        [handlerSearch]="handlerSearch"
        [placeholder]="placeholder"
        [displayFilter]="false"></fz-search>
    </div>

    <span class="spinner" *ngIf="isLoading"></span>

    <div class="cards-container">
      <mat-card class="card clickable" [routerLink]="['/p', company.slug, 'services']"
        *ngFor="let company of fiteredCompanys || companys">
        <div class="company-avatar">
          <picture>
            <img [src]="company.avatarUrl" alt="company.name">
          </picture>
        </div>

        <div class="company-info">
          <h4 class="name">{{company.name}}</h4>
          <p class="description" *ngIf="company.description">{{company.description}}</p>
        </div>

        <div class="company-category">
          <p>{{ 'categories.' + company.category | translate}}</p>
        </div>
      </mat-card>

      <div *ngIf="fiteredCompanys && fiteredCompanys.length === 0" class="message warning">
        <div class="icon-container">
          <i class="fa fa-coffee" aria-hidden="true"></i>
        </div>
        <p class="text-message">{{'messages.not-result.title' | translate}}</p>
      </div>
    </div>
  </article>
</section>
