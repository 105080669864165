<section>
  <header id="cover">
    <div class="text">
      <h1>{{'home.cover.title' | translate }}</h1>
    </div>

    <div class="buttons">
      <div>
        <a href="https://app.filazero.net/?campanha=filazero-site#/quick-start">
          <button mat-raised-button class="btn-fz">{{'actions.register-app' | translate }}</button>
        </a>
      </div>
      <div>
        <span (click)="scroll('#contact')">
          <button mat-raised-button>{{'actions.tutorial' | translate }}</button>
        </span>
      </div>
    </div>

    <div id="rolldown">
      <i class="fa fa-angle-double-down clickable" (click)="scroll('#brief')"></i>
    </div>
  </header>

  <article id="brief">
    <div class="container">
      <p>{{'home.brief.description' | translate }}</p>
    </div>
  </article>

  <article id="how-it-works">
    <div class="container">
      <h1>{{'home.how-it-works.title' | translate }}</h1>

      <div class="item-container">
        <div class="item" *ngFor="let step of how_it_works; let i = index">
          <div>
            <div class="icon-container">
              <img src="../../../assets/icons/home/how-it-works/{{step}}.svg" />
              <span class="counter">{{ i + 1 }}</span>
            </div>
          </div>
          <span>
            <strong>{{'home.how-it-works.items.' + step + '.title' | translate }}</strong>
            {{'home.how-it-works.items.' + step + '.description' | translate }}
          </span>
        </div>
      </div>

      <img id="isometric" src="../../../assets/images/home/isometric.png" />
    </div>

    <div id="catchphrases" class="container">
      <p>{{ 'home.catchphrases.p0' | translate }}</p>
      <p>{{ 'home.catchphrases.p1' | translate }}</p>
      <p>{{ 'home.catchphrases.p2' | translate }}</p>
      <p>{{ 'home.catchphrases.p3' | translate }}</p>
    </div>
  </article>

  <article id="stats">
    <div class="container">
      <h1>{{'home.stats.title' | translate }}</h1>

      <div class="card" *ngFor="let stat of stats">
        <img src="../../../assets/icons/home/stats/{{stat}}.svg" />
        <div>
          <h2 [innerHTML]="'home.stats.items.' + stat + '.title' | translate"></h2>
          <p>{{'home.stats.items.' + stat + '.description' | translate }}</p>
        </div>
      </div>
    </div>
  </article>

  <article id="cases-and-awards">
    <div class="container">
      <h1>{{'home.cases.title' | translate }}</h1>
      <p>{{'home.cases.description' | translate }}</p>

      <div class="img-container">
        <span *ngFor="let case of cases">
          <img src="../../../assets/images/home/cases/{{case}}.svg" alt="{{case | capitalize}}" />
        </span>
      </div>
    </div>

    <div class="container">
      <h1>{{'home.awards.title' | translate }}</h1>

      <div class="img-container">
        <a href="https://www.unimedpoa.com.br/fique-por-dentro/noticias/Paginas/BemStartupUnimeddivulgaasempresasvencedorasdaprimeiraetapa.aspx">
          <img src="../../../assets/images/home/awards/bem-startup-unimed.png" alt="Vencedora do Bem Startup Unimed" />
        </a>
        <!--
        <a href="http://www.portaldaindustria.com.br/agenciacni/noticias/2017/09/empresas-whirlpool-e-cogel-buscam-projetos-de-startups-com-oferta-de-ate-r-150-mil-no-edital-de-inovacao-para-a-industria/">
          <img src="../../../assets/images/home/awards/edital-senai-inovacao.jpeg" alt="Filazero no edital Senai de Inovação + ALGAR" />
        </a>
         -->
        <a href="http://www.inovasabin.com.br/wp-content/uploads/2017/09/divulgacao_8_finalistas_vs_site.pdf">
          <img src="../../../assets/images/home/awards/inova-sabin.png" alt="Filazero no SABIN" />
        </a>
        <a href="https://www.inovativabrasil.com.br/inovativa-brasil-divulga-as-132-finalistas-do-primeiro-ciclo-de-aceleracao-de-2017/">
          <img src="../../../assets/images/home/awards/inovativa-brasil.svg" alt="Filazero Inovativa" />
        </a>
      </div>
    </div>
  </article>

  <article id="benefits">
    <div class="container">
      <h1>{{'home.benefits.title' | translate }}</h1>
      <p>{{'home.benefits.description' | translate }}</p>

      <h2>{{'home.benefits.business-client.title' | translate }}</h2>
      <div class="item-container business-client">
        <div class="item" *ngFor="let benefit of benefits_bc">
          <img src="../../../assets/icons/home/benefits/business-client/{{benefit}}.svg" />
          <span>
            <h3>{{'home.benefits.business-client.items.' + benefit + '.title' | translate }}</h3>
            <div>
              <strong>{{'home.benefits.business-client.items-labels.business' | translate }}</strong>
              {{'home.benefits.business-client.items.' + benefit + '.business' | translate }}
            </div>
            <div>
              <strong>{{'home.benefits.business-client.items-labels.client' | translate }}</strong>
              {{'home.benefits.business-client.items.' + benefit + '.client' | translate }}
            </div>
          </span>
        </div>
      </div>

      <h2>{{'home.benefits.problem-solution.title' | translate }}</h2>
      <div class="item-container problem-solution" *ngFor="let benefit of benefits_ps">
        <div class="item problem">
          <img src="../../../assets/icons/home/benefits/problem-solution/{{benefit}}-problem.svg" />
          <span>
            <strong>{{'home.benefits.problem-solution.items-labels.problem' | translate }}</strong>
            {{'home.benefits.problem-solution.items.' + benefit + '.problem' | translate }}
          </span>
        </div>
        <div class="item solution">
          <img src="../../../assets/icons/home/benefits/problem-solution/{{benefit}}-solution.svg" />
          <span>
            <strong>{{'home.benefits.problem-solution.items-labels.solution' | translate }}</strong>
            {{'home.benefits.problem-solution.items.' + benefit + '.solution' | translate }}
          </span>
        </div>
      </div>
    </div>
  </article>

  <article id="contact">
    <div class="container">
      <fz-contact></fz-contact>
    </div>
  </article>

  <i id="back-to-top" class="fa fa-angle-double-up clickable" (click)="scroll('body')"></i>
</section>
