import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SearchComponent } from './search.component';
import { MaterialModule } from '../../../utils/material.module';

@NgModule({
  imports: [
    FormsModule,
    TranslateModule,
    CommonModule,
    MaterialModule,
  ],
  declarations: [
    SearchComponent,
  ],
  exports: [
    SearchComponent,
  ]
})
export class SearchModule { }
