import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from './../../core/services/auth.service';
import { Contact } from '../../shared/models/contact.model';
import { EmailService } from '../../shared/services/email.service';
import { EventService } from '../../shared/services/event.service';
import { NotifyService } from './../../core/services/notify.service';
import { finalize } from 'rxjs/operators';
import { TranslatorService } from 'src/app/core/services/translator.service';

@Component({
  selector: 'fz-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {
  contact: Contact;
  formSent = false;
  isLoading = false;
  contactForm: FormGroup;

  constructor(
    private _emailService: EmailService,
    private _formBuilder: FormBuilder,
    private _notify: NotifyService,
    private _router: Router,
    private _authService: AuthService,
    private _translator: TranslatorService
  ) {
    const index = this._router.url.lastIndexOf('/');
    const name = this._router.url.substring(index + 1);
    if (name === 'contact') {
      EventService.changeTitle.emit('contact');
    }
    this.contact = new Contact();
  }

  ngOnInit() {
    if (this._authService.isAuth()) {
      this.getUserInfo();
    }
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.contactForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(15)]],
      emailFrom: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
      message: ['', [Validators.required]]
    });
  }

  private getUserInfo() {
    this._authService.profileInfo()
      .subscribe((response) => {
        if (response) {
          this.contactForm.patchValue({
            name: `${response.user.firstName} ${response.user.lastName}`,
            emailFrom: response.user.userName,
            phone: response.user.phone
          });
        }
      });
  }

  sendEmail() {
    this.isLoading = true;
    this.contact = this.contactForm.value;
    this.contact.subject = this._translator.get('contact.subject');
    this._emailService.send(this.contact).pipe(finalize(() => this.isLoading = false))
      .subscribe((res) => {
        if (res.messages[0].type === 'SUCCESS') {
          this.contactForm.markAsUntouched();
          this.formSent = true;
        }
      },
        (error) => this._notify.displayNotity(error));
  }

}
