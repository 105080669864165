<header class="noprint" [ngClass]="{ 'reduce': !isMenuExpanded }" [style.background-color]="template.templateColor || '#530f6d'">
  <nav class="menu" [ngClass]="{ 'expand': isMenuExpanded }" [style.background-color]="template.templateColor || '#530f6d'">
    <button *ngIf="!displayBackButton" class="btn-icon menu-icon" (click)="menuToggle()" [ngClass]="{ 'menu-itens': !isMenuExpanded }">
      <div id="side-menu" [ngClass]="{ 'close': isMenuExpanded }">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
    <button *ngIf="displayBackButton" class="btn-icon menu-icon" (click)="backRoute()">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <figure class="menu-logo">
      <a [routerLink]="logoRoute" (click)="menuReduce()">
        <img [src]="template.logoUrl || logoFilazero" alt="Logo">
      </a>
      <figcaption>Logo Filazero</figcaption>
    </figure>

    <div class="list-container" [ngClass]="{ 'menu-itens': !isMenuExpanded }" [style.background-color]="template.templateColor || '#530f6d'">

      <ul>
        <li *ngIf="user && isAuth()">
          <div class="menu-display">
            <mat-icon class="user-icon">account_circle</mat-icon>
            <span>{{user.firstName}} {{user.lastName}}</span>
            <span class="user-email">{{user.email}}</span>
          </div>
        </li>
        <li class="btn-scheduling" *ngIf="!template.templateColor">
          <a [routerLink]="['explore']" (click)="menuReduce()">
            <mat-icon fontSet="fa" fontIcon="fa-calendar"></mat-icon>
            <span>{{'header.scheduling' | translate}}</span>
          </a>
        </li>
        <div class="menu-display" *ngIf="isAuth()">
          <li>
            <a href="/my-account/tickets">
              <mat-icon fontSet="fa" fontIcon="fa-ticket"></mat-icon>
              <span>{{'header.my-tickets' | translate}}</span>
            </a>
          </li>
          <li>
            <a href="/my-account/data-edit">
              <mat-icon>edit</mat-icon>
              <span>{{'my-account.change-personal-info' | translate}}</span>
            </a>
          </li>
          <li>
            <a href="#" (click)="logOut()">
              <mat-icon>exit_to_app</mat-icon>
              <span>{{'actions.sign-out' | translate}}</span>
            </a>
          </li>
        </div>
        <li *ngIf="!isAuth()">
          <a routerLinkActive="active" [routerLink]="['/user/register']" (click)="menuReduce()">
            <mat-icon fontSet="fa" fontIcon="fa-user"></mat-icon>
            <span>{{'header.create-account' | translate}}</span>
          </a>
        </li>
        <li *ngIf="!isAuth()">
          <a routerLinkActive="active" [routerLink]="['/user/login']" (click)="menuReduce()">
            <mat-icon fontSet="fa" fontIcon="fa-sign-in"></mat-icon>
            <span>{{'actions.sign-in' | translate}}</span>
          </a>
        </li>
        <li>
          <div *ngIf="user && isAuth()" class="user-info">
            <button class="big-screen" mat-button [matMenuTriggerFor]="menu">
              <mat-icon class="user-icon">account_circle</mat-icon>
              <span> {{user.firstName}} {{user.lastName}}</span>
            </button>
            <mat-menu #menu="matMenu" overlapTrigger="false">
              <a [routerLink]="['/my-account/tickets']">
                <button mat-menu-item>
                  <mat-icon class="ticket-icon" fontSet="fa" fontIcon="fa-ticket"></mat-icon>
                  <span>{{'header.my-tickets' | translate}}</span>
                </button>
              </a>
              <a [routerLink]="['/my-account/data-edit']">
                <button mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>{{'my-account.change-personal-info' | translate}}</span>
                </button>
              </a>
              <button mat-menu-item (click)="logOut()">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{'actions.sign-out' | translate}}</span>
              </button>
            </mat-menu>
          </div>
        </li>
      </ul>
    </div>

    <div class="icon-container" [ngClass]="{ 'menu-itens': !isMenuExpanded }" [style.background-color]="template.templateColor || '#530f6d'">
      <button class="btn-icon">
        <a href="https://www.instagram.com/filazeroapp/?hl=pt-br" target="_blank">
          <i class="fa fa-instagram" aria-hidden="true"></i>
        </a>
      </button>

      <button class="btn-icon">
        <a href="https://pt-br.facebook.com/filazero/" target="_blank">
          <i class="fa fa-facebook" aria-hidden="true"></i>
        </a>
      </button>

      <button class="btn-icon">
        <a href="https://www.linkedin.com/company/filazero/" target="_blank">
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </a>
      </button>
    </div>
  </nav>
</header>
