import { Component, OnInit } from '@angular/core';
import { GeocodingService } from './shared/services/geocoding.service';
import { EventService } from './shared/services/event.service';
import { Title } from '@angular/platform-browser';
import { TitleCustomize } from './modules/scheduling/models/title-customize.model';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from './core/services/translator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'fz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  constructor(
    private _geocodingService: GeocodingService,
    private _snackBar: MatSnackBar,
    private _swUpdate: SwUpdate,
    private _titleService: Title,
    private _translate: TranslateService,
    private _translator: TranslatorService
  ) {
    this._geocodingService.loadMapsAPI();
    this._translate.setDefaultLang('pt');

    EventService.changeLanguage
      .subscribe((lang: string) => {
        this._translate.setDefaultLang(lang);
    });

    EventService.changeTitle.subscribe((title: string) => {
      this._translate.get(`title.${title}`)
        .subscribe((res: string) => {
          this._titleService.setTitle(`Filazero - ${res}`);
      });
    });

    EventService.changeTitleCustomize.subscribe((title: TitleCustomize) => {
      this._translate.get(`title.${title.context}`)
        .subscribe((res: string) => {
          this._titleService.setTitle(
            `${this.capitalize(title.provider)} - ${res}`
        );
      });
    });
  }

  ngOnInit() {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.available.subscribe(() => {
        const msg = this._translator.get('messages.success.load-version');
        const action = this._translator.get('actions.confirm');
        const snackBarRef = this._snackBar.open(msg, action, {
          duration: 150000
        });
        snackBarRef.onAction().subscribe(() => {
          window.location.reload();
        });
      });
    }
  }

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
