export class Company {
  id: number;
  name: string;
  avatarUrl: string;
  slug: string;
  description: string;
}

export class Template {
  templateColor: string;
  fontColor: string;
  logoUrl: string;
}
