import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { finalize, debounceTime, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Company } from '../scheduling/models/company.model';
import { NotifyService } from '../../core/services/notify.service';
import { SearchService } from '../../shared/services/search.service';
import { EventService } from '../../shared/services/event.service';

@Component({
  selector: 'fz-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.sass']
})
export class ExploreComponent implements OnInit, OnDestroy {
  companys: Company[] = [];
  fiteredCompanys: Company[] = undefined;
  @Output() placeholder = 'Encontre local de agendamento...';
  @Output() search: string;
  @Output() handlerSearch: EventEmitter<string> = new EventEmitter<string>();
  searchTextChanged: Subject<string>;
  isLoading = false;
  isLoadingSearch = false;

  constructor(
    private _notify: NotifyService,
    private _searchService: SearchService
  ) {
    EventService.changeTitle.emit('explore');

    this.handlerSearch
      .subscribe((search) => {
        this.search = search;
        this.handlesearchCompanies(search);
      });
  }

  ngOnInit() {
    this.searchTextChanged = new Subject<string>();
    this.getCompanies();
    this.searchCompanies();
    EventService.changeButtonBack.emit(false);
  }

  ngOnDestroy() {
    EventService.changeButtonBack.emit(true);
  }

  getCompanies(): void {
    this.isLoading = true;
    this._searchService.getCompanies()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((response: Company[]) => {
        this.companys = response;
      },
      error => {
        this._notify.displayNotity(error);
      });
  }

  searchCompanies(): void {
    this.searchTextChanged
      .pipe(
        debounceTime(400),
        switchMap(search => this._searchService.searchCompanies(search)),
        finalize(() => this.isLoading = false)
      )
      .subscribe((response) => {
        if (response && this.search) {
          this.fiteredCompanys = response;
          this.isLoadingSearch = false;
        } else {
          this.fiteredCompanys = undefined;
        }
      },
      error => {
        this._notify.displayNotity(error);
      });
  }

  handlesearchCompanies(search: string): void {
    if (search) {
      this.isLoadingSearch = true;
      this.searchTextChanged.next(search.trim());
    } else {
      this.isLoadingSearch = false;
      this.fiteredCompanys = undefined;
    }
  }
}
